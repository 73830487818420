import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { id: "Moves" }
const _hoisted_2 = { class: "table table-striped" }
const _hoisted_3 = ["onUpdate:modelValue"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("h2", null, "Moves", -1)),
    _createElementVNode("table", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("thead", { class: "thead-dark" }, [
        _createElementVNode("tr", null, [
          _createElementVNode("th", { scope: "col" }, "Cards in play"),
          _createElementVNode("th", { scope: "col" }, "Action"),
          _createElementVNode("th", { scope: "col" }, "Valid move"),
          _createElementVNode("th", { scope: "col" }, "Reason"),
          _createElementVNode("th", { scope: "col" }, "Optimum move")
        ])
      ], -1)),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.moves, (move) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: move.toString()
          }, [
            _createElementVNode("td", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(move.cards, (card) => {
                return (_openBlock(), _createElementBlock("i", {
                  key: card.toString(),
                  style: _normalizeStyle({'color' : card.suit.colour})
                }, _toDisplayString(card.toString()) + "   ", 5))
              }), 128))
            ]),
            _createElementVNode("td", null, _toDisplayString(move.action), 1),
            _createElementVNode("td", null, [
              _withDirectives(_createElementVNode("input", {
                type: "checkbox",
                "onUpdate:modelValue": ($event: any) => ((move.valid) = $event),
                disabled: ""
              }, null, 8, _hoisted_3), [
                [_vModelCheckbox, move.valid]
              ])
            ]),
            _createElementVNode("td", null, _toDisplayString(move.moveCategory), 1),
            _createElementVNode("td", null, _toDisplayString(move.optimumAction), 1)
          ]))
        }), 128))
      ])
    ])
  ]))
}