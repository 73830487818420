
import { Options, Vue } from 'vue-class-component'

@Options({
  components: {}
})
export default class Timeline extends Vue {
  puppyModal = false;
  storyModal = false;
  thatVisible = false;
}
