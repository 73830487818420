import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "sorting-algorithms" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 1,
  class: "custom-chart"
}
const _hoisted_5 = { class: "options" }
const _hoisted_6 = { class: "d-flex justify-content-between" }
const _hoisted_7 = { class: "d-flex justify-content-between" }
const _hoisted_8 = { class: "d-flex justify-content-end mt-2" }
const _hoisted_9 = { class: "d-flex justify-content-end mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_highcharts = _resolveComponent("highcharts")!
  const _component_it_switch = _resolveComponent("it-switch")!
  const _component_it_select = _resolveComponent("it-select")!
  const _component_it_number_input = _resolveComponent("it-number-input")!
  const _component_it_button = _resolveComponent("it-button")!
  const _component_it_button_group = _resolveComponent("it-button-group")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[12] || (_cache[12] = _createElementVNode("div", { class: "description" }, [
      _createElementVNode("h1", null, "Sorting Algorithm Visualiser"),
      _createElementVNode("p", null, "A classic coding exercise demonstrating how sorting algorithms work.")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.temp > 0)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, "Temp: " + _toDisplayString(_ctx.temp), 1))
        : _createCommentVNode("", true),
      (_ctx.chartType)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataSet, (number, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "bar",
                key: index,
                style: _normalizeStyle({height: ((number * 10) + 10) + 'px', backgroundColor: _ctx.getColour(index)})
              }, _toDisplayString(number), 5))
            }), 128))
          ]))
        : (_openBlock(), _createBlock(_component_highcharts, {
            key: 2,
            type: "chart",
            ref: "chart",
            options: _ctx.chartOptions
          }, null, 8, ["options"]))
    ]),
    _createElementVNode("div", _hoisted_5, [
      _cache[10] || (_cache[10] = _createElementVNode("hr", null, null, -1)),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_it_switch, {
          modelValue: _ctx.chartType,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.chartType) = $event)),
          "hide-controls": true,
          label: _ctx.labelName
        }, null, 8, ["modelValue", "label"]),
        _createVNode(_component_it_select, {
          modelValue: _ctx.speed,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.speed) = $event)),
          placement: "top",
          options: _ctx.speedOptions
        }, null, 8, ["modelValue", "options"]),
        _createVNode(_component_it_number_input, {
          modelValue: _ctx.dataSetSize,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.dataSetSize) = $event)),
          "label-left": "Data set size",
          "resize-on-write": "",
          min: 1,
          max: 40,
          disabled: _ctx.operating
        }, null, 8, ["modelValue", "disabled"])
      ]),
      _cache[11] || (_cache[11] = _createElementVNode("hr", null, null, -1)),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_it_button_group, null, {
          default: _withCtx(() => [
            _createVNode(_component_it_button, {
              type: "black",
              outlined: "",
              onClick: _ctx.initialiseData,
              disabled: _ctx.operating
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("Initialise")
              ])),
              _: 1
            }, 8, ["onClick", "disabled"]),
            _createVNode(_component_it_button, {
              type: "black",
              outlined: "",
              onClick: _ctx.randomiseData,
              disabled: _ctx.operating
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode("Randomise")
              ])),
              _: 1
            }, 8, ["onClick", "disabled"])
          ]),
          _: 1
        }),
        _createVNode(_component_it_button_group, null, {
          default: _withCtx(() => [
            _createVNode(_component_it_button, {
              class: "btn-sort",
              type: "black",
              outlined: "",
              onClick: _ctx.bubbleSort,
              disabled: _ctx.operating
            }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createTextVNode("Bubble Sort")
              ])),
              _: 1
            }, 8, ["onClick", "disabled"]),
            _createVNode(_component_it_button, {
              class: "btn-sort-optimised",
              type: "black",
              outlined: "",
              onClick: _ctx.bubbleSortOptimised,
              disabled: _ctx.operating
            }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createTextVNode("Bubble Sort (Optimised)")
              ])),
              _: 1
            }, 8, ["onClick", "disabled"])
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_it_button_group, null, {
          default: _withCtx(() => [
            _createVNode(_component_it_button, {
              class: "btn-sort",
              type: "black",
              outlined: "",
              onClick: _ctx.selectionSort,
              disabled: _ctx.operating
            }, {
              default: _withCtx(() => _cache[7] || (_cache[7] = [
                _createTextVNode("Selection Sort")
              ])),
              _: 1
            }, 8, ["onClick", "disabled"]),
            _createVNode(_component_it_button, {
              class: "btn-sort-optimised",
              type: "black",
              outlined: "",
              onClick: _ctx.selectionSortOptimised,
              disabled: _ctx.operating
            }, {
              default: _withCtx(() => _cache[8] || (_cache[8] = [
                _createTextVNode("Selection Sort (Optimised)")
              ])),
              _: 1
            }, 8, ["onClick", "disabled"])
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_it_button, {
          class: "btn-sort-full",
          type: "black",
          outlined: "",
          onClick: _ctx.insertionSort,
          disabled: _ctx.operating
        }, {
          default: _withCtx(() => _cache[9] || (_cache[9] = [
            _createTextVNode("Insertion Sort")
          ])),
          _: 1
        }, 8, ["onClick", "disabled"])
      ])
    ])
  ]))
}