import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/clock.jpg'
import _imports_1 from '@/assets/cards-2.jpg'


const _hoisted_1 = { class: "card-game-container clock-solitaire" }
const _hoisted_2 = { class: "row play-area" }
const _hoisted_3 = { class: "col-sm-10" }
const _hoisted_4 = { class: "main" }
const _hoisted_5 = { class: "face-down" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "text-white" }
const _hoisted_8 = { class: "col-sm-2" }
const _hoisted_9 = { class: "playing-card-header" }
const _hoisted_10 = { class: "playing-card-footer" }
const _hoisted_11 = {
  key: 0,
  src: _imports_0
}
const _hoisted_12 = {
  key: 1,
  src: _imports_1
}
const _hoisted_13 = {
  key: 0,
  class: "modal-title"
}
const _hoisted_14 = {
  key: 1,
  class: "modal-title"
}
const _hoisted_15 = { class: "d-flex justify-content-between" }
const _hoisted_16 = { key: 0 }
const _hoisted_17 = { key: 1 }
const _hoisted_18 = {
  key: 0,
  class: "stats"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_it_button = _resolveComponent("it-button")!
  const _component_it_divider = _resolveComponent("it-divider")!
  const _component_it_button_group = _resolveComponent("it-button-group")!
  const _component_it_modal = _resolveComponent("it-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[13] || (_cache[13] = _createElementVNode("h3", { class: "title-bar" }, "Around The Clock Patience", -1)),
    _createElementVNode("div", {
      class: _normalizeClass(["red-border start-div fade-class", {'fade-screen': _ctx.startGame, 'position-absolute': _ctx.startGame}])
    }, [
      _createVNode(_component_it_button, {
        type: "black",
        outlined: "",
        onClick: _ctx.openRules
      }, {
        default: _withCtx(() => _cache[4] || (_cache[4] = [
          _createTextVNode("start")
        ])),
        _: 1
      }, 8, ["onClick"])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["deck fade-class", {'fade-screen': !_ctx.startGame, 'position-fixed': !_ctx.startGame}])
    }, [
      _createVNode(_component_it_divider),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visibleCards, (card, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: _normalizeClass(["card card-sm outer-circle", {'d-none': card.length === 0}])
              }, [
                (card.length > 0)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createElementVNode("i", {
                        class: _normalizeClass(["playing-card-header", {'inverted': card.at(-1).rank > 3 && card.at(-1).rank < 10}]),
                        style: _normalizeStyle({'color' : card.at(-1).suit.colour})
                      }, _toDisplayString(card.at(-1).suit.icon), 7),
                      _createElementVNode("span", {
                        class: _normalizeClass(["playing-card-body", {
                    'inverted': card.at(-1).rank > 3 && card.at(-1).rank < 10,
                    'underlined': card.at(-1).rank == 6 || card.at(-1).rank == 9
                  }]),
                        style: _normalizeStyle({'color' : card.at(-1).suit.colour})
                      }, _toDisplayString(card.at(-1).rank), 7),
                      _createElementVNode("i", {
                        class: _normalizeClass(["playing-card-footer", {'inverted': card.at(-1).rank > 3 && card.at(-1).rank < 10}]),
                        style: _normalizeStyle({'color' : card.at(-1).suit.colour})
                      }, _toDisplayString(card.at(-1).suit.icon), 7)
                    ], 64))
                  : _createCommentVNode("", true)
              ], 2))
            }), 128)),
            _createElementVNode("div", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hiddenCards, (card, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: _normalizeClass(["card card-xs bg-red inner-circle", {'d-none': card.count === 0}]),
                  key: index,
                  onClick: ($event: any) => (_ctx.animate(card.rank))
                }, [
                  _createElementVNode("span", _hoisted_7, _toDisplayString(card.count), 1)
                ], 10, _hoisted_6))
              }), 128))
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", {
            class: "card card-lg ml-3",
            style: _normalizeStyle({'color' : _ctx.topCard.suit.colour})
          }, [
            _createElementVNode("i", _hoisted_9, _toDisplayString(_ctx.topCard.suit.icon), 1),
            _createElementVNode("span", {
              class: _normalizeClass(["playing-card-body", {'underlined': _ctx.topCard.rank == 6 || _ctx.topCard.rank == 9}])
            }, _toDisplayString(_ctx.topCard.rank), 3),
            _createElementVNode("i", _hoisted_10, _toDisplayString(_ctx.topCard.suit.icon), 1)
          ], 4),
          _createVNode(_component_it_button_group, {
            vertical: "",
            class: "mt-4 actions-bar"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_it_button, {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.rulesModal = true))
              }, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createTextVNode("Rules")
                ])),
                _: 1
              }),
              _createVNode(_component_it_button, {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.restart()))
              }, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode("Restart")
                ])),
                _: 1
              })
            ]),
            _: 1
          })
        ])
      ])
    ], 2),
    _createVNode(_component_it_modal, {
      modelValue: _ctx.rulesModal,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.rulesModal) = $event))
    }, {
      header: _withCtx(() => _cache[7] || (_cache[7] = [
        _createElementVNode("h3", { class: "modal-title" }, "Rules", -1)
      ])),
      body: _withCtx(() => _cache[8] || (_cache[8] = [
        _createElementVNode("p", { class: "mb-2" }, "All 52 cards are dealt in piles of 4 in the shape of a circle, with each pile representing a card value and its matching clock face.", -1),
        _createElementVNode("hr", null, null, -1),
        _createElementVNode("p", null, "Ace => 1, Two => 2 ... Queen => 12", -1),
        _createElementVNode("hr", null, null, -1),
        _createElementVNode("p", null, "The King is represented by a 13th middle pile and only has 3 cards. The final card is your starting point. You must match the card with its position in the clock, then take a card from its pile and so on and so forth. To win the game you must complete the clock before getting the final king, as it has only 3 draws, unlike the rest which have 4.", -1),
        _createElementVNode("hr", null, null, -1),
        _createElementVNode("p", null, "This game is 100% luck, so don't worry about missing anything and have fun.", -1)
      ])),
      actions: _withCtx(() => [
        _createVNode(_component_it_button, {
          type: "danger",
          onClick: _ctx.closeRules
        }, {
          default: _withCtx(() => _cache[9] || (_cache[9] = [
            _createTextVNode("Got it!")
          ])),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_it_modal, {
      modelValue: _ctx.finishModal,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.finishModal) = $event))
    }, {
      image: _withCtx(() => [
        (_ctx.winner)
          ? (_openBlock(), _createElementBlock("img", _hoisted_11))
          : (_openBlock(), _createElementBlock("img", _hoisted_12))
      ]),
      header: _withCtx(() => [
        (_ctx.winner)
          ? (_openBlock(), _createElementBlock("h3", _hoisted_13, "Winner!"))
          : (_openBlock(), _createElementBlock("h3", _hoisted_14, "Unlucky"))
      ]),
      body: _withCtx(() => [
        _createElementVNode("div", _hoisted_15, [
          (_ctx.winner)
            ? (_openBlock(), _createElementBlock("p", _hoisted_16, "Well done you managed to Complete the Clock"))
            : (_openBlock(), _createElementBlock("p", _hoisted_17, "Only " + _toDisplayString(_ctx.score) + " cards left, better luck next time", 1)),
          _createVNode(_component_it_button, {
            outlined: "",
            onClick: _ctx.toggleStats
          }, {
            default: _withCtx(() => _cache[10] || (_cache[10] = [
              _createTextVNode("Stats")
            ])),
            _: 1
          }, 8, ["onClick"])
        ]),
        (_ctx.stats)
          ? (_openBlock(), _createElementBlock("div", _hoisted_18, _cache[11] || (_cache[11] = [
              _createElementVNode("hr", null, null, -1),
              _createElementVNode("p", { class: "mb-2" }, "The odds of winning this game are 1 in 13 or ~7.69%, but maybe the ultimate goal is to lose as fast as possible.", -1),
              _createElementVNode("p", { class: "mb-2" }, "If your first 4 cards were kings you'd be way out of luck, or would you, as this is has a 1 in 270,275 or ~0.00037%. Winning the lottery with 6 numbers is 1 in 45,057,474, but getting 5 numbers is 1 in 144,415.", -1),
              _createElementVNode("p", null, "This means you are statistically more likely to get 5 numbers in the lottery, £8,538 on a jackpot rolldown week, than get a perfect loss in this game.", -1)
            ])))
          : _createCommentVNode("", true)
      ]),
      actions: _withCtx(() => [
        _createVNode(_component_it_button, {
          type: "danger",
          onClick: _ctx.finish
        }, {
          default: _withCtx(() => _cache[12] || (_cache[12] = [
            _createTextVNode("Play Again")
          ])),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}