import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "body-styles" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_header = _resolveComponent("custom-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_custom_footer = _resolveComponent("custom-footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[0] || (_cache[0] = _createElementVNode("link", {
      rel: "stylesheet",
      href: "https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined"
    }, null, -1)),
    _cache[1] || (_cache[1] = _createElementVNode("link", {
      href: "https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;900&display=swap",
      rel: "stylesheet"
    }, null, -1)),
    _createElementVNode("header", null, [
      _createVNode(_component_custom_header)
    ]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_view)
    ]),
    _createElementVNode("footer", null, [
      _createVNode(_component_custom_footer)
    ])
  ], 64))
}