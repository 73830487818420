import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '@/assets/nellie/nellie-1.jpg'


const _hoisted_1 = { class: "timeline-container" }
const _hoisted_2 = { class: "main-timeline" }
const _hoisted_3 = { class: "timeline" }
const _hoisted_4 = { class: "date-content" }
const _hoisted_5 = { class: "date-outer" }
const _hoisted_6 = { class: "date" }
const _hoisted_7 = { class: "month" }
const _hoisted_8 = { class: "timeline" }
const _hoisted_9 = { class: "date-content" }
const _hoisted_10 = { class: "date-outer" }
const _hoisted_11 = { class: "date" }
const _hoisted_12 = { class: "month" }
const _hoisted_13 = { class: "timeline-content" }
const _hoisted_14 = { class: "timeline" }
const _hoisted_15 = { class: "timeline-content" }
const _hoisted_16 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_17 = {
  key: 1,
  class: "text-danger"
}
const _hoisted_18 = {
  key: 2,
  class: "text-danger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!
  const _component_it_button = _resolveComponent("it-button")!
  const _component_it_modal = _resolveComponent("it-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[7] || (_cache[7] = _createElementVNode("div", { class: "icon" }, null, -1)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, [
              _createElementVNode("span", _hoisted_7, [
                _createVNode(_component_fa_icon, { icon: "chevron-up" })
              ]),
              _cache[6] || (_cache[6] = _createElementVNode("span", { class: "year" }, "2025", -1))
            ])
          ])
        ]),
        _cache[8] || (_cache[8] = _createStaticVNode("<div class=\"timeline-content\"><h5 class=\"title\">Grid Picks</h5><p class=\"description\"> A task I set myself was <a href=\"https://web.gridpicks.co.uk\">Grid Picks*</a>. A personal project where, using <a href=\"https://github.com/onebeyond/onebeyond-studio-obelisk\">Obelisk</a>, I would design, create, manage and host my own website in Azure and following all the correct methodologies. No corner cutting here (mostly). Furthering my skills and underline everything I have learnt at my 4 years at One Beyond. The MVP was done in time for Christmas and I now hope to bring the rest of the project to life in the coming months. All while of course taking advantage of being unemployed. * -&gt; Name/brand/logo is TBD. </p></div>", 1))
      ]),
      _cache[18] || (_cache[18] = _createStaticVNode("<div class=\"timeline\"><div class=\"icon\"></div><div class=\"date-content\"><div class=\"date-outer\"><span class=\"date\"><span class=\"month\">September</span><span class=\"year\">2024</span></span></div></div><div class=\"timeline-content\"><h5 class=\"title\">Future Endeavours</h5><p class=\"description\"> After 4 years at One Beyond I decided to leave and persue something else. While in employment, it can difficult to take action on some side projects or ideas you may want to have a go at. Doing the same thing after work as at work doesn&#39;t have the same appeal and a hobby can then feel like work. So I decided I need to leave and push myself to the next thing. Not being reactive to something like redundancy, but proactive in taking a risk on the next thing. </p></div></div>", 1)),
      _createElementVNode("div", _hoisted_8, [
        _cache[13] || (_cache[13] = _createElementVNode("div", { class: "icon" }, null, -1)),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("span", _hoisted_11, [
              _createElementVNode("span", _hoisted_12, [
                _createVNode(_component_fa_icon, { icon: "chevron-up" })
              ]),
              _cache[9] || (_cache[9] = _createElementVNode("span", { class: "year" }, "2022", -1))
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _cache[11] || (_cache[11] = _createElementVNode("h5", { class: "title" }, "Present (contemporary)", -1)),
          _cache[12] || (_cache[12] = _createElementVNode("p", { class: "description" }, " Over 2022 I have further developed my personal website, completing the existing 'Hand Patience' card game as well as adding another game, 'Clock Solitaire'. In September I added a sorting visualisation tool, something I had always admired, not due to its complexity, but its satifying aestetics. Outside of work and hobbies, we got a puppy in January. Nellie the boxer has grown over the year, going from a tiny 2kg to a still small 18kg. ", -1)),
          _createVNode(_component_it_button, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.puppyModal = true))
          }, {
            default: _withCtx(() => _cache[10] || (_cache[10] = [
              _createTextVNode("...")
            ])),
            _: 1
          })
        ])
      ]),
      _cache[19] || (_cache[19] = _createStaticVNode("<div class=\"timeline\"><div class=\"icon\"></div><div class=\"date-content\"><div class=\"date-outer\"><span class=\"date\"><span class=\"month\">August</span><span class=\"year\">2022</span></span></div></div><div class=\"timeline-content\"><h5 class=\"title\">Software Engineer @ One Beyond</h5><p class=\"description\"> Over the past 7 months I have been working directly with the client in an augmentation project. I&#39;ve worked hard as a part of their team and together we have continued to migrate workflows from their access database to a web application. Their happiness is proven with the 2 contract extensions to keep me onboard. </p></div></div><div class=\"timeline\"><div class=\"icon\"></div><div class=\"date-content\"><div class=\"date-outer\"><span class=\"date\"><span class=\"month\"> October</span><span class=\"year\">2021</span></span></div></div><div class=\"timeline-content\"><h5 class=\"title\">Junior Software Developer @ One Beyond</h5><p class=\"description\"> Moving to Junior Developer, I work more independently and am entrusted to work on more demanding projects. In this position I worked on a government system used to help make decisions based on value rather than cost. </p></div></div><div class=\"timeline\"><div class=\"icon\"></div><div class=\"date-content\"><div class=\"date-outer\"><span class=\"date\"><span class=\"month\"> August</span><span class=\"year\">2020</span></span></div></div><div class=\"timeline-content\"><h5 class=\"title\">Graduate Software Developer @ One Beyond</h5><p class=\"description\"> First job after University I started during of pandemic, I am enjoying the role and learning far more as a developer than other experience before. I&#39;ve had a good start, already making myself known as a good presenter and leading team demos across multiple projects. </p></div></div>", 3)),
      _createElementVNode("div", _hoisted_14, [
        _cache[17] || (_cache[17] = _createStaticVNode("<div class=\"icon\"></div><div class=\"date-content\"><div class=\"date-outer\"><span class=\"date\"><span class=\"month\">1 Year</span><span class=\"year\">2019</span></span></div></div>", 2)),
        _createElementVNode("div", _hoisted_15, [
          _cache[15] || (_cache[15] = _createElementVNode("h5", { class: "title" }, "Graduate University @ Surrey", -1)),
          _cache[16] || (_cache[16] = _createElementVNode("p", { class: "description" }, " I finished University with a 2:1. When the pandemic hit, I found it difficult to stay focused and work on my studies. However, I knuckled down and achieved 69% for my Final Year Project. I am very proud of this achievement as I restarted and finished my project within 4 weeks of the deadline. ", -1)),
          _createVNode(_component_it_button, {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.storyModal = true))
          }, {
            default: _withCtx(() => _cache[14] || (_cache[14] = [
              _createTextVNode("...")
            ])),
            _: 1
          })
        ])
      ]),
      _cache[20] || (_cache[20] = _createStaticVNode("<div class=\"timeline\"><div class=\"icon\"></div><div class=\"date-content\"><div class=\"date-outer\"><span class=\"date\"><span class=\"month\">1 Year</span><span class=\"year\">2018</span></span></div></div><div class=\"timeline-content\"><h5 class=\"title\">Junior Systems Engineer @ NoteMachine</h5><p class=\"description\"> My university offered a placement year where you work in industry between your 2nd and 3rd years. After starting I realised they do not give you a place, you still need to apply for jobs, competing against all your peers across the country. During my year out I worked for NoteMachine where I contributed to the Video Surround system; a project created and developed by previous years&#39; placement students. Here I created a web dashboard to monitor and control all the machines across the country as well as leading the product to a successful IPX5 rating. </p></div></div><div class=\"timeline\"><div class=\"icon\"></div><div class=\"date-content\"><div class=\"date-outer\"><span class=\"date\"><span class=\"month\">2 Years</span><span class=\"year\">2016</span></span></div></div><div class=\"timeline-content\"><h5 class=\"title\">Computer Science @ Surrey</h5><p class=\"description\"> I chose to study Computer Science as I have a great interest in computing and programming. While I learnt most of my coding skills in a work environment, having a good breadth of knowledge has helped me apply these skills across all stages of the development cycle. </p></div></div>", 2))
    ]),
    _createVNode(_component_it_modal, {
      modelValue: _ctx.puppyModal,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.puppyModal) = $event))
    }, {
      image: _withCtx(() => _cache[21] || (_cache[21] = [
        _createElementVNode("img", {
          src: _imports_0,
          width: "400"
        }, null, -1)
      ])),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_it_modal, {
      modelValue: _ctx.storyModal,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.storyModal) = $event))
    }, {
      header: _withCtx(() => _cache[22] || (_cache[22] = [
        _createElementVNode("h3", { class: "modal-title" }, "My Dissertation", -1)
      ])),
      body: _withCtx(() => [
        _createElementVNode("p", null, [
          _cache[23] || (_cache[23] = _createTextVNode("Even though I was very pushed for time - restarting with 4 weeks left, with a whole project to code and a dissertation to write - I decided to give myself another challenge. I find ")),
          (_ctx.thatVisible)
            ? (_openBlock(), _createElementBlock("span", _hoisted_16, "THAT "))
            : _createCommentVNode("", true),
          _cache[24] || (_cache[24] = _createTextVNode(" the word \"that\" is always overused and is not a good demonstration of an articulate writer. Most of the time ")),
          (_ctx.thatVisible)
            ? (_openBlock(), _createElementBlock("span", _hoisted_17, "THAT "))
            : _createCommentVNode("", true),
          _cache[25] || (_cache[25] = _createTextVNode("it's used it's not needed, and times ")),
          (_ctx.thatVisible)
            ? (_openBlock(), _createElementBlock("span", _hoisted_18, "THAT "))
            : _createCommentVNode("", true),
          _cache[26] || (_cache[26] = _createTextVNode(" it is needed, the scentence should be re-written to prevent its use. I succesfully wrote the full 18k words on 55 pages and managed not to use the word \"that\". This did get a little screwed up when the mandatory Declaration of Originality uses it 7 times, the bibliography once and the SAGE report another 15 times. Nevertheless nowhere in my work is the word \"that\" used."))
        ]),
        _createVNode(_component_it_button, {
          onMouseenter: _cache[3] || (_cache[3] = ($event: any) => (_ctx.thatVisible = true)),
          onMouseleave: _cache[4] || (_cache[4] = ($event: any) => (_ctx.thatVisible = false))
        }, {
          default: _withCtx(() => _cache[27] || (_cache[27] = [
            _createTextVNode("...")
          ])),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}