import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/winner.jpg'
import _imports_1 from '@/assets/loser.jpg'
import _imports_2 from '@/assets/cards.jpg'


const _hoisted_1 = { class: "card-game-container" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "d-flex" }
const _hoisted_4 = { class: "previous-card" }
const _hoisted_5 = { class: "playing-card-header" }
const _hoisted_6 = { class: "playing-card-body" }
const _hoisted_7 = { class: "playing-card-footer" }
const _hoisted_8 = { class: "face-down side-deck" }
const _hoisted_9 = {
  key: 0,
  class: "card-xl-reverse bg-blue"
}
const _hoisted_10 = {
  key: 1,
  class: "card-xl-reverse bg-red stack-middle"
}
const _hoisted_11 = {
  key: 2,
  class: "card-xl-reverse bg-blue stack-top"
}
const _hoisted_12 = {
  key: 0,
  class: "card card-label"
}
const _hoisted_13 = { class: "row" }
const _hoisted_14 = { class: "row" }
const _hoisted_15 = { class: "text-end card-game-container" }
const _hoisted_16 = { class: "row d-flex" }
const _hoisted_17 = { class: "playing-card-header suit-font-sm" }
const _hoisted_18 = { class: "playing-card-body rank-font-sm" }
const _hoisted_19 = { class: "playing-card-footer suit-font-sm" }
const _hoisted_20 = { class: "row" }
const _hoisted_21 = { class: "playing-card-header suit-font-sm" }
const _hoisted_22 = { class: "playing-card-body rank-font-sm" }
const _hoisted_23 = { class: "playing-card-footer suit-font-sm" }
const _hoisted_24 = { class: "row" }
const _hoisted_25 = { class: "playing-card-header suit-font-sm" }
const _hoisted_26 = { class: "playing-card-body rank-font-sm" }
const _hoisted_27 = { class: "playing-card-footer suit-font-sm" }
const _hoisted_28 = { class: "d-flex justify-content-between" }
const _hoisted_29 = {
  key: 0,
  src: _imports_0
}
const _hoisted_30 = {
  key: 1,
  src: _imports_1
}
const _hoisted_31 = {
  key: 2,
  src: _imports_2
}
const _hoisted_32 = { class: "modal-title" }
const _hoisted_33 = { key: 0 }
const _hoisted_34 = { key: 1 }
const _hoisted_35 = { key: 2 }
const _hoisted_36 = { key: 3 }
const _hoisted_37 = { class: "d-flex justify-content-between" }
const _hoisted_38 = { class: "row advanced-options" }
const _hoisted_39 = { class: "col-6" }
const _hoisted_40 = { class: "col-6 d-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_it_button = _resolveComponent("it-button")!
  const _component_it_divider = _resolveComponent("it-divider")!
  const _component_it_button_group = _resolveComponent("it-button-group")!
  const _component_move_table = _resolveComponent("move-table")!
  const _component_it_checkbox = _resolveComponent("it-checkbox")!
  const _component_it_modal = _resolveComponent("it-modal")!
  const _component_it_number_input = _resolveComponent("it-number-input")!
  const _component_it_icon = _resolveComponent("it-icon")!
  const _component_it_tooltip = _resolveComponent("it-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[46] || (_cache[46] = _createElementVNode("h3", { class: "title-bar" }, "Hand Patience", -1)),
    _createElementVNode("div", {
      class: _normalizeClass(["blue-border start-div fade-class", {'fade-screen': _ctx.startGame, 'position-absolute': _ctx.startGame}])
    }, [
      _createVNode(_component_it_button, {
        type: "black",
        outlined: "",
        onClick: _ctx.openRules
      }, {
        default: _withCtx(() => _cache[22] || (_cache[22] = [
          _createTextVNode("start")
        ])),
        _: 1
      }, 8, ["onClick"])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["deck fade-class", {'fade-screen': !_ctx.startGame, 'position-fixed': !_ctx.startGame}])
    }, [
      _createVNode(_component_it_divider),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (_ctx.lastCard)
              ? (_openBlock(), _createElementBlock("sup", {
                  key: 0,
                  style: _normalizeStyle({'color' : _ctx.lastCard.suit.secondaryColour})
                }, _toDisplayString(_ctx.lastCard.toString()), 5))
              : _createCommentVNode("", true)
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visibleCards, (card, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(["card card-xl", {'highlight' : _ctx.calculateHover(index)}]),
              key: card.toString() + index,
              style: _normalizeStyle({'color' : card.suit.colour})
            }, [
              _createElementVNode("i", _hoisted_5, _toDisplayString(card.suit.icon), 1),
              _createElementVNode("span", _hoisted_6, _toDisplayString(card.rank), 1),
              _createElementVNode("i", _hoisted_7, _toDisplayString(card.suit.icon), 1)
            ], 6))
          }), 128)),
          _createElementVNode("div", _hoisted_8, [
            (_ctx.remainingCards > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9))
              : _createCommentVNode("", true),
            (_ctx.remainingCards > 1)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10))
              : _createCommentVNode("", true),
            (_ctx.remainingCards > 2)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  (_ctx.remainingCards > 3)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.remainingCards), 1))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _createVNode(_component_it_divider),
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_it_button_group, {
          class: "justify-content-center m-2",
          horizontal: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_it_button, {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.discard())),
              pulse: _ctx.easy && _ctx.canDiscardSet,
              disabled: _ctx.tutorial && !_ctx.canDiscardSet,
              onMouseenter: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onHoverDiscardHand = true)),
              onMouseleave: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.onHoverDiscardHand = false), ["stop"]))
            }, {
              default: _withCtx(() => _cache[23] || (_cache[23] = [
                _createTextVNode("Discard Hand")
              ])),
              _: 1
            }, 8, ["pulse", "disabled"]),
            _createVNode(_component_it_button, {
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.discardCentral())),
              pulse: _ctx.easy && _ctx.canDiscardCentral,
              disabled: _ctx.tutorial && !_ctx.canDiscardCentral,
              onMouseenter: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onHoverDiscardCore = true)),
              onMouseleave: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (_ctx.onHoverDiscardCore = false), ["stop"]))
            }, {
              default: _withCtx(() => _cache[24] || (_cache[24] = [
                _createTextVNode("Discard Core")
              ])),
              _: 1
            }, 8, ["pulse", "disabled"]),
            (!_ctx.isAtMaximum)
              ? (_openBlock(), _createBlock(_component_it_button, {
                  key: 0,
                  onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.next()))
                }, {
                  default: _withCtx(() => _cache[25] || (_cache[25] = [
                    _createTextVNode("Draw")
                  ])),
                  _: 1
                }))
              : (_openBlock(), _createBlock(_component_it_button, {
                  key: 1,
                  onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.finishModal = true))
                }, {
                  default: _withCtx(() => _cache[26] || (_cache[26] = [
                    _createTextVNode("Finish")
                  ])),
                  _: 1
                })),
            _createVNode(_component_it_button, { disabled: "" }, {
              default: _withCtx(() => _cache[27] || (_cache[27] = [
                _createTextVNode(" - ")
              ])),
              _: 1
            }),
            _createVNode(_component_it_button, {
              onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.restart()))
            }, {
              default: _withCtx(() => _cache[28] || (_cache[28] = [
                _createTextVNode("Restart")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_it_divider),
      _createElementVNode("div", _hoisted_14, [
        _createVNode(_component_it_button, {
          class: "m-2",
          onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.rulesModal = true))
        }, {
          default: _withCtx(() => _cache[29] || (_cache[29] = [
            _createTextVNode("Rules")
          ])),
          _: 1
        }),
        _createVNode(_component_it_button, {
          class: "m-2",
          onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.advancedModal = true))
        }, {
          default: _withCtx(() => _cache[30] || (_cache[30] = [
            _createTextVNode("Advanced Options")
          ])),
          _: 1
        })
      ])
    ], 2),
    (_ctx.deck.moves.length > 0 && _ctx.moveList)
      ? (_openBlock(), _createBlock(_component_move_table, {
          key: 0,
          moves: _ctx.deck.moves
        }, null, 8, ["moves"]))
      : _createCommentVNode("", true),
    _createVNode(_component_it_modal, {
      modelValue: _ctx.rulesModal,
      "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.rulesModal) = $event))
    }, {
      header: _withCtx(() => _cache[31] || (_cache[31] = [
        _createElementVNode("h3", { class: "modal-title" }, "Rules", -1)
      ])),
      body: _withCtx(() => [
        _cache[36] || (_cache[36] = _createElementVNode("p", { class: "mb-2" }, "The aim of this game is to end up with the fewest cards left in you hand. Starting with 4 cards look at the first and last in your hand. If they are the same suit, discard the middle 2 cards; if they are the same number/rank, discard all 4; otherwise draw another.", -1)),
        _cache[37] || (_cache[37] = _createElementVNode("p", null, "As you draw and discard cards, you cycle back and forth through the deck, ending when the deck is finished. Your score is the number of cards you discard.", -1)),
        _cache[38] || (_cache[38] = _createElementVNode("br", null, null, -1)),
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("div", _hoisted_16, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rules1Deck, (card, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "card highlight card-md",
                key: card.toString() + index,
                style: _normalizeStyle({'color' : card.suit.colour})
              }, [
                _createElementVNode("i", _hoisted_17, _toDisplayString(card.suit.icon), 1),
                _createElementVNode("span", _hoisted_18, _toDisplayString(card.rank), 1),
                _createElementVNode("i", _hoisted_19, _toDisplayString(card.suit.icon), 1)
              ], 4))
            }), 128)),
            _cache[32] || (_cache[32] = _createElementVNode("div", { class: "col" }, " Matching Rank - Discard Full Hand ", -1))
          ]),
          _createVNode(_component_it_divider),
          _createElementVNode("div", _hoisted_20, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rules2Deck, (card, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["card card-md", {'highlight' : index == 1 || index === 2}]),
                key: card.toString() + index,
                style: _normalizeStyle({'color' : card.suit.colour})
              }, [
                _createElementVNode("i", _hoisted_21, _toDisplayString(card.suit.icon), 1),
                _createElementVNode("span", _hoisted_22, _toDisplayString(card.rank), 1),
                _createElementVNode("i", _hoisted_23, _toDisplayString(card.suit.icon), 1)
              ], 6))
            }), 128)),
            _cache[33] || (_cache[33] = _createElementVNode("div", { class: "col" }, " Matching Suit - Discard Core ", -1))
          ]),
          _createVNode(_component_it_divider),
          _createElementVNode("div", _hoisted_24, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rules3Deck, (card, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "card card-md",
                key: card.toString() + index,
                style: _normalizeStyle({'color' : card.suit.colour})
              }, [
                _createElementVNode("i", _hoisted_25, _toDisplayString(card.suit.icon), 1),
                _createElementVNode("span", _hoisted_26, _toDisplayString(card.rank), 1),
                _createElementVNode("i", _hoisted_27, _toDisplayString(card.suit.icon), 1)
              ], 4))
            }), 128)),
            _cache[34] || (_cache[34] = _createElementVNode("div", { class: "col" }, " Nothing - Draw ", -1))
          ])
        ]),
        _cache[39] || (_cache[39] = _createElementVNode("br", null, null, -1)),
        _createElementVNode("div", _hoisted_28, [
          _cache[35] || (_cache[35] = _createElementVNode("p", null, "There's only 3 buttons, you'll work it out.", -1)),
          _createVNode(_component_it_checkbox, {
            class: "m-5",
            type: "primary",
            label: "Tutorial mode",
            modelValue: _ctx.tutorial,
            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.tutorial) = $event))
          }, null, 8, ["modelValue"])
        ])
      ]),
      actions: _withCtx(() => [
        _createVNode(_component_it_button, {
          type: "primary",
          onClick: _cache[12] || (_cache[12] = ($event: any) => {_ctx.rulesModal = false; _ctx.startGame = true;})
        }, {
          default: _withCtx(() => _cache[40] || (_cache[40] = [
            _createTextVNode("Got it!")
          ])),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_it_modal, {
      modelValue: _ctx.finishModal,
      "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.finishModal) = $event))
    }, {
      image: _withCtx(() => [
        (_ctx.winner && !_ctx.deck.cheater)
          ? (_openBlock(), _createElementBlock("img", _hoisted_29))
          : (_ctx.loser)
            ? (_openBlock(), _createElementBlock("img", _hoisted_30))
            : (_openBlock(), _createElementBlock("img", _hoisted_31))
      ]),
      header: _withCtx(() => [
        _createElementVNode("h3", _hoisted_32, _toDisplayString(_ctx.score) + " / 52", 1)
      ]),
      body: _withCtx(() => [
        (_ctx.deck.cheater)
          ? (_openBlock(), _createElementBlock("p", _hoisted_33, " Woopsy... You used an illegal move, check the movelist to see where you went wrong"))
          : (_ctx.winner)
            ? (_openBlock(), _createElementBlock("p", _hoisted_34, "Woo Hoo! - Great Score"))
            : (_ctx.loser)
              ? (_openBlock(), _createElementBlock("p", _hoisted_35, "Oh dear... "))
              : (_openBlock(), _createElementBlock("p", _hoisted_36, "Well Done - but there's room for improvement"))
      ]),
      actions: _withCtx(() => [
        (_ctx.deck.cheater)
          ? (_openBlock(), _createBlock(_component_it_button, {
              key: 0,
              type: "warning",
              onClick: _ctx.cheaterFinish
            }, {
              default: _withCtx(() => _cache[41] || (_cache[41] = [
                _createTextVNode("See Moves")
              ])),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true),
        _createVNode(_component_it_button, {
          type: "primary",
          onClick: _ctx.finish
        }, {
          default: _withCtx(() => _cache[42] || (_cache[42] = [
            _createTextVNode("Play Again")
          ])),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_it_modal, {
      modelValue: _ctx.advancedModal,
      "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.advancedModal) = $event))
    }, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_37, [
          _cache[43] || (_cache[43] = _createElementVNode("h3", { class: "modal-title" }, "Advanced Options ", -1)),
          _createVNode(_component_it_button, {
            icon: "refresh",
            onClick: _ctx.resetOptions
          }, null, 8, ["onClick"])
        ])
      ]),
      body: _withCtx(() => [
        _createElementVNode("div", _hoisted_38, [
          _createElementVNode("div", _hoisted_39, [
            _createVNode(_component_it_checkbox, {
              class: "m-5",
              type: "black",
              label: "Tutorial Mode",
              modelValue: _ctx.tutorial,
              "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.tutorial) = $event))
            }, null, 8, ["modelValue"]),
            _createVNode(_component_it_checkbox, {
              class: "m-5",
              type: "black",
              label: "Hints",
              modelValue: _ctx.easy,
              "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.easy) = $event))
            }, null, 8, ["modelValue"]),
            _createVNode(_component_it_checkbox, {
              class: "m-1",
              type: "black",
              label: "Move List",
              modelValue: _ctx.moveList,
              "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.moveList) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_40, [
            _createVNode(_component_it_number_input, {
              modelValue: _ctx.advancedOptions.jokers,
              "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.advancedOptions.jokers) = $event)),
              "label-top": "Jokers",
              "resize-on-write": "",
              min: 0,
              max: 10
            }, null, 8, ["modelValue"]),
            _createVNode(_component_it_tooltip, {
              placement: "top",
              content: "Jokers do not have a suit, only a rank"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_it_icon, {
                  name: "help",
                  outlined: ""
                })
              ]),
              _: 1
            })
          ])
        ])
      ]),
      actions: _withCtx(() => [
        _createVNode(_component_it_button, {
          onClick: _cache[19] || (_cache[19] = ($event: any) => (_ctx.advancedModal = false))
        }, {
          default: _withCtx(() => _cache[44] || (_cache[44] = [
            _createTextVNode("Cancel")
          ])),
          _: 1
        }),
        _createVNode(_component_it_button, {
          type: "primary",
          onClick: _cache[20] || (_cache[20] = ($event: any) => (_ctx.restart(), _ctx.advancedModal = false))
        }, {
          default: _withCtx(() => _cache[45] || (_cache[45] = [
            _createTextVNode("Apply now")
          ])),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}