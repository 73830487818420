import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card-game-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_it_button = _resolveComponent("it-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[2] || (_cache[2] = _createElementVNode("h3", { class: "title-bar" }, "Klondike", -1)),
    _createElementVNode("div", {
      class: _normalizeClass(["green-border start-div fade-class", {'fade-screen': _ctx.startGame, 'position-absolute': _ctx.startGame}])
    }, [
      _createVNode(_component_it_button, {
        type: "black",
        outlined: "",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.startGame = true))
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode("start")
        ])),
        _: 1
      })
    ], 2),
    (_ctx.startGame)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(["stamp", {'fade-screen': !_ctx.startGame, 'position-fixed': !_ctx.startGame}])
        }, "Coming Soon", 2))
      : _createCommentVNode("", true)
  ]))
}