import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "farey-algorithms" }
const _hoisted_2 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_it_button = _resolveComponent("it-button")!
  const _component_it_number_input = _resolveComponent("it-number-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[13] || (_cache[13] = _createElementVNode("div", { class: "description" }, [
      _createElementVNode("h1", null, "Farey's Algorithm"),
      _createElementVNode("p", null, [
        _createElementVNode("strong", null, "NOTE:"),
        _createTextVNode("This page is incopmlete and still under construction")
      ]),
      _createElementVNode("p", null, "The method calculators use to convert complex decimal numbers to a more visually appealing fraction."),
      _createElementVNode("div", { class: "text-start" }, [
        _createElementVNode("p", null, "Notable errors:"),
        _createElementVNode("ol", null, [
          _createElementVNode("li", null, "Javascript has at most 17 significant figures"),
          _createElementVNode("li", null, "Stack limit count (too many recursive calls because the decimal is too close to the limits, e.g. 1.0000000000001)")
        ])
      ])
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_it_button, {
        type: "black",
        outlined: "",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.farey(_ctx.inputVal)))
      }, {
        default: _withCtx(() => _cache[6] || (_cache[6] = [
          _createTextVNode("Convert to Fraction")
        ])),
        _: 1
      }),
      _createVNode(_component_it_number_input, {
        modelValue: _ctx.inputVal,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.inputVal) = $event)),
        "label-left": "Input Number",
        style: {"width":"125px"},
        "hide-controls": true,
        min: 0
      }, null, 8, ["modelValue"]),
      _cache[12] || (_cache[12] = _createElementVNode("p", null, "Examples", -1)),
      _createVNode(_component_it_button, {
        type: "black",
        outlined: "",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.farey(10.3)))
      }, {
        default: _withCtx(() => _cache[7] || (_cache[7] = [
          _createTextVNode("10.3")
        ])),
        _: 1
      }),
      _createVNode(_component_it_button, {
        type: "black",
        outlined: "",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.farey(2.765)))
      }, {
        default: _withCtx(() => _cache[8] || (_cache[8] = [
          _createTextVNode("2.765")
        ])),
        _: 1
      }),
      _createVNode(_component_it_button, {
        type: "black",
        outlined: "",
        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.farey(0.495)))
      }, {
        default: _withCtx(() => _cache[9] || (_cache[9] = [
          _createTextVNode("0.495")
        ])),
        _: 1
      }),
      _createVNode(_component_it_button, {
        type: "black",
        outlined: "",
        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.farey(123.456)))
      }, {
        default: _withCtx(() => _cache[10] || (_cache[10] = [
          _createTextVNode("123.456")
        ])),
        _: 1
      }),
      _createElementVNode("span", null, [
        _createElementVNode("sup", null, _toDisplayString(_ctx.outputVal[0]), 1),
        _cache[11] || (_cache[11] = _createTextVNode(" ⁄ ")),
        _createElementVNode("sub", null, _toDisplayString(_ctx.outputVal[1]), 1)
      ])
    ])
  ]))
}