import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/face.jpg'


const _hoisted_1 = { class: "about" }
const _hoisted_2 = { class: "d-flex justify-content-around" }
const _hoisted_3 = { class: "name-label" }
const _hoisted_4 = { class: "description mx-1" }
const _hoisted_5 = { class: "mb-1" }
const _hoisted_6 = { class: "mb-2" }
const _hoisted_7 = { class: "mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_my_timeline = _resolveComponent("my-timeline")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("img", {
        class: "profile-picture",
        src: _imports_0,
        alt: "profile"
      }, null, -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", null, [
          _cache[0] || (_cache[0] = _createElementVNode("span", null, "{", -1)),
          _createTextVNode(_toDisplayString(_ctx.$t("title.hi")), 1)
        ]),
        _createElementVNode("h1", null, [
          _createTextVNode(_toDisplayString(_ctx.$t("title.name")), 1),
          _cache[1] || (_cache[1] = _createElementVNode("span", null, "}", -1))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("description.line1")), 1),
      _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t("description.line2")), 1),
      _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t("description.line3")), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.$t("description.line4")), 1)
    ]),
    _createVNode(_component_my_timeline)
  ]))
}