
import { Options, Vue } from 'vue-class-component'
import Dictionary from '@localizations/buildAWebsite'
import router from '@/router'

@Options({
  i18n: {
    messages: Dictionary
  }
})
export default class BuildAWebsite extends Vue {
  collapsable = true;

  loadPage (path: string): void {
    router.push({ name: path })
  }
}
